<template>
<div class="h-full">
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
        <div slot="buttonGroup" class="h-handle-button">
            <div class="h-b">
                <el-button type="primary" size="small" @click="add" v-right-code="'Contractinfo:Create'">新增</el-button>
            </div>
            <div class="h-b">
                <el-button type="primary" size="small" @click="enable" v-right-code="'Contractinfo:Batchenable'">启用</el-button>
            </div>
            <div class="h-b">
                <el-button type="primary" size="small" @click="disable" v-right-code="'Contractinfo:Batchenable'">停用</el-button>
            </div>
            <div class="h-b">
                <el-button type="primary" size="small" @click="copyData" v-right-code="'Contractinfo:Copycontract'">复制</el-button>
            </div>
            <!-- <div class="h-b">
                <el-button type="primary" size="small" @click="exportData" v-right-code="'/contractinfo/batchdisable'">导出</el-button>
            </div> -->
        </div>
        <p slot="elList">
            <el-table ref="userListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index" :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)" :fixed="index==0&&(!config||!config.isDetailDisplay)" :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0" v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                    <template slot-scope="scope">
                        <span v-if="col.FieldName==='Code'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                        <span v-else-if="col.FieldName==='DisplayStatus'" width="100%">
                            <el-tag :type="scope.row.DisplayStatus=='已停用' ? 'danger' :  ( scope.row.DisplayStatus=='执行中'?'success':'info')">{{ scope.row[col.FieldName] }}</el-tag>
                        </span>
                        <span v-else> {{ scope.row[col.FieldName] }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-right-code="'Contractinfo:Create'" style="margin-left:10px;" @click="edit(scope.row)">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </p>
    </fixed-list>
</div>
</template>

<script>
export default {
    mounted() {
        this.Event.$on("reloadPageList", () => this.reloadPageList());
        this.Event.$on("onAdd", () => this.add());
        this.initialize();
    },
    data() {
        return {
            multipleSelection: [],
            isPassValidate: true,
            queryParam: {
                PageIndex: 1,
                PageSize: 10
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },
            tStaffOptions: {
                visible: false
            },
            Id: '',
            Name: ''
        }
    },

    props: {
        config: {
            isDetailDisplay: false,
            isButtonlDisplay: false,
        },
        option: {}
    },
    methods: {
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        onPageChange(param) {
            var _this = this;
            this.queryParam = param;
            this.$ajax.query("omsapi/contractinfo/getpagelist", "post", param, data => {
                _this.dataSource = data;
            });
        },
        onDataSourceChange(ds) {
            var _this = this;

            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });
        },
        rowClick(row) {
            this.$ajax.send("omsapi/contractinfo/getbyid", "get", {
                id: row.Id
            }, (data) => {
                this.Event.$emit("clearEditUserForm");
                data.Result.IsEdit=false;
                this.onChangeEditDataSource(data.Result);
            });
        },
        edit(row){
            this.$ajax.send("omsapi/contractinfo/getbyid", "get", {
                id: row.Id
            }, (data) => {
                this.Event.$emit("clearEditUserForm");
                data.Result.IsEdit=true;
                this.onChangeEditDataSource(data.Result);
            });
        },
        add() {
            this.$ajax.send("omsapi/contractinfo/getempty", "get", {}, (data) => {
                data.Result.IsEdit=true;
                this.onChangeEditDataSource(data.Result);
            });
        },
        enable() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择启用的合同.`, "error");
                return false;
            }

            this.$ajax.send("omsapi/contractinfo/batchenable", "post", this.multipleSelection, (data) => {
                this.onPageChange(this.queryParam);
                this.Utils.messageBox("合同启用成功.", "success");
            });
        },
        disable() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择停用合同.`, "error");
                return false;
            }

            this.$ajax.send("omsapi/contractinfo/batchdisable", "post", this.multipleSelection, (data) => {
                this.onPageChange(this.queryParam);
                this.Utils.messageBox("合同停用成功.", "success");
            });
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        },
        copyData() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择复制合同.`, "error");
                return false;
            }
            if(this.multipleSelection.length>1){
                this.Utils.messageBox(`只能选择一条合同.`, "error");
                return false;
            }
            this.$ajax.send("omsapi/contractinfo/copycontract", "post", this.multipleSelection[0], (data) => {
                this.onPageChange(this.queryParam);
                this.Utils.messageBox("复制成功.", "success");
            });
        },
        exportData() {

        }
    },
    components: {}
}
</script>

<style>

</style>
